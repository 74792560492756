import { createSlice } from '@reduxjs/toolkit'
import Course = App.Models.Course

export interface CourseFilters {
  show_saved: boolean
  search: string
  course_fees: string[]
  countries: string[]
  university_names: string[]
  course_levels: string[]
  course_type: string
  course_intakes: string[]
  academic_entry_requirements: string
  board_university: string
  min_marks_required: string
  min_percentage_required: string
  was_maths_required_in_ssc: boolean | null
  min_marks_in_ssc: string
  min_percentage_in_ssc: string
  ielts_overall_score: string
  ielts_listening_score: string
  ielts_reading_score: string
  ielts_writing_score: string
  ielts_speaking_score: string
  pte_overall_score: string
  pte_listening_score: string
  pte_reading_score: string
  pte_writing_score: string
  pte_speaking_score: string
  toefl_overall_score: string
  toefl_listening_score: string
  toefl_reading_score: string
  toefl_writing_score: string
  toefl_speaking_score: string
  duolingo_overall_score: string
  duolingo_listening_score: string
  duolingo_reading_score: string
  duolingo_writing_score: string
  duolingo_speaking_score: string
  commission: string
  portal: string
}

export interface CourseState {
  filters: CourseFilters
  selectedCourseIds: number[]
  savedCourseIds: number[]
  selected_course: Course | null
  activeTab: number
}

const initialState: CourseState = {
  filters: {
    show_saved: false,
    search: '',
    course_fees: [],
    countries: [],
    university_names: [],
    course_levels: [],
    course_type: '',
    course_intakes: [],
    academic_entry_requirements: '',
    board_university: '',
    min_marks_required: '',
    min_percentage_required: '',
    was_maths_required_in_ssc: null,
    min_marks_in_ssc: '',
    min_percentage_in_ssc: '',
    ielts_overall_score: '',
    ielts_listening_score: '',
    ielts_reading_score: '',
    ielts_writing_score: '',
    ielts_speaking_score: '',
    pte_overall_score: '',
    pte_listening_score: '',
    pte_reading_score: '',
    pte_writing_score: '',
    pte_speaking_score: '',
    toefl_overall_score: '',
    toefl_listening_score: '',
    toefl_reading_score: '',
    toefl_writing_score: '',
    toefl_speaking_score: '',
    duolingo_overall_score: '',
    duolingo_listening_score: '',
    duolingo_reading_score: '',
    duolingo_writing_score: '',
    duolingo_speaking_score: '',
    commission: '',
    portal: '' ?? 'Agency',
  },
  selectedCourseIds: [],
  savedCourseIds: [],
  selected_course: null,
  activeTab: 0,
}

export const courseFinderSlice = createSlice({
  name: 'courseFinderSlice',
  initialState,
  reducers: {
    setCourseFinderFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    updateSelectedCourseIdToCourses: (state, action) => {
      state.selectedCourseIds = action.payload
    },
    updateSavedCourseIdToCourses: (state, action) => {
      state.savedCourseIds = action.payload
    },
    updateSetPortal: (state, action) => {
      state.filters.portal = action.payload
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setCourseFinderFilters,
  updateSelectedCourseIdToCourses,
  updateSavedCourseIdToCourses,
  updateSetPortal,
  updateActiveTab,
} = courseFinderSlice.actions

export default courseFinderSlice.reducer
