import React, { FC, useEffect } from 'react'
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react'
import { usePage } from '@inertiajs/react'
import { showNotification } from '@mantine/notifications'
import AdminSidebar from '@/Components/Admin/AdminSidebar'
import { HStack } from '@/XelaReact'

const AdminAuthenticated: FC<{ children: React.ReactNode }> = ({ children }) => {
  const flash = usePage().props.flash as Record<string, unknown>

  useEffect(() => {
    if (flash.message) {
      showNotification({
        withCloseButton: true,
        autoClose: 5000,
        title: 'Success',
        message: flash.message as string,
        color: 'green',
        icon: <IconCheck />,
      })
    }

    if (flash.error) {
      showNotification({
        withCloseButton: true,
        autoClose: 5000,
        title: 'Error',
        message: flash.error as string,
        color: 'red',
        icon: <IconAlertTriangle />,
      })
    }
  }, [flash.timestamp, flash.message, flash.error])

  return (
    <div className="w-full overflow-x-hidden" style={{ position: 'relative', height: '100vh' }}>
      <HStack width="100%" height="100%" alignItems="flex-start">
        <AdminSidebar />
        {children}
      </HStack>
    </div>
  )
}

export default AdminAuthenticated
