import { configureStore } from '@reduxjs/toolkit'
import userSliceReducer from '@/Store/userSlice'
import dialogSliceReducer from '@/Store/dialogSlice'
import leadDrawerReducer from '@/Store/leadDrawerSlice'
import leadSliceReducer from '@/Store/leadSlice'
import counsellingSliceReducer from '@/Store/counsellingSlice'
import admissionSliceReducer from '@/Store/admissionSlice'
import admissionApplicationSliceReducer from '@/Store/admissionApplicationSlice'
import visaSliceReducer from '@/Store/visaSlice'
import taskSliceReducer from '@/Store/taskSlice'
import followUpSliceReducer from '@/Store/followUpSlice'
import courseSliceReducer from '@/Store/courseSlice'
import courseV2SliceReducer from '@/Store/courseV2Slice'
import globalSliceReducer from '@/Store/globalSlice'
import hubLeadSliceReducer from '@/Store/Hub/hubLeadSlice'
import b2bAgentSliceReducer from '@/Store/B2B/b2bAgentSlice'
import b2bLeadSliceReducer from '@/Store/B2B/b2bLeadSlice'
import leadFormSliceReducer from '@/Store/leadFormSlice'
import paymentSliceReducer from '@/Store/paymentSlice'
import metaSliceReducer from '@/Store/metaSlice'
import emailSliceReducer from '@/Store/emailSlice'
import adminAgencySliceReducer from '@/Store/Admin/adminAgencySlice'
import adminMetaCourseSliceReducer from '@/Store/Admin/adminMetaCourseSlice'
import adminAgencyCourseSliceReducer from '@/Store/Admin/adminAgencyCourseSlice'
import visitorLeadSliceReducer from '@/Store/visitorLeadSlice'
import ieltsLeadSliceReducer from '@/Store/ieltsLeadSlice'
import dashboardSliceReducer from '@/Store/dashboardSlice'
import automationSliceReducer from '@/Store/automationSlice'
import b2bAnnouncementSliceReducer from '@/Store/B2B/b2bAnnouncementSlice'
import coachingLeadSliceReducer from '@/Store/coachingLeadSlice'
import entityFormSliceReducer from '@/Store/entityFormSlice'
import insuranceLeadSliceReducer from '@/Store/insuranceLeadSlice'
import insuranceCommissionSliceReducer from '@/Store/insuranceCommissionSlice'
import adminInsuranceCompanySliceReducer from '@/Store/Admin/adminInsuranceCompanySlice'
import adminInsurancePolicySliceReducer from '@/Store/Admin/adminInsurancePolicySlice'
import adminInsuranceOrderSliceReducer from '@/Store/Admin/adminInsuranceOrderSlice'
import adminInsuranceCompanyInvoiceSliceReducer from '@/Store/Admin/adminInsuranceCompanyInvoiceSlice'
import adminInsuranceAgencyInvoiceSliceReducer from '@/Store/Admin/adminInsuranceAgencyInvoiceSlice'
import universityPaymentSliceReducer from '@/Store/universityPaymentSlice'
import announcementSliceReducer from '@/Store/announcementSlice'
import workflowSliceReducer from '@/Store/workflowSlice'
import b2bDashboardSliceReducer from '@/Store/B2B/b2bDashboardSlice'
import hubDashboardSliceReducer from '@/Store/Hub/hubDashboardSlice'
import b2bCommissionInvoiceReducer from '@/Store/B2B/b2bCommissionInvoiceSlice'
import hubCommissionInvoiceReducer from '@/Store/Hub/hubCommissionInvoiceSlice'
import b2bCommissionLeadReducer from '@/Store/B2B/b2bCommissionLeadSlice'
import b2bContractSliceReducer from '@/Store/B2B/b2bContractSlice'
import migrateLeadSliceReducer from '@/Store/migrateLeadSlice'
import loanSliceReducer from '@/Store/loanSlice'
import hubSubAgentSliceReducer from '@/Store/Hub/hubSubAgentSlice'
import hubVisitorLeadSliceReducer from '@/Store/Hub/hubVisitorLeadSlice'
import b2bVisitorLeadReducer from '@/Store/B2B/b2bVisitorLeadSlice'
import courseFinderSliceReducer from '@/Store/courseFinderSlice'

export const store = configureStore({
  reducer: {
    user: userSliceReducer,
    dialog: dialogSliceReducer,
    leadDrawer: leadDrawerReducer,
    lead: leadSliceReducer,
    counselling: counsellingSliceReducer,
    dashboard: dashboardSliceReducer,
    admission: admissionSliceReducer,
    automation: automationSliceReducer,
    admissionApplication: admissionApplicationSliceReducer,
    visa: visaSliceReducer,
    task: taskSliceReducer,
    followUp: followUpSliceReducer,
    course: courseSliceReducer,
    courseV2: courseV2SliceReducer,
    global: globalSliceReducer,
    hubLead: hubLeadSliceReducer,
    b2bAgent: b2bAgentSliceReducer,
    b2bLead: b2bLeadSliceReducer,
    b2bAnnouncement: b2bAnnouncementSliceReducer,
    leadForm: leadFormSliceReducer,
    payment: paymentSliceReducer,
    meta: metaSliceReducer,
    email: emailSliceReducer,
    adminAgency: adminAgencySliceReducer,
    adminInsuranceCompany: adminInsuranceCompanySliceReducer,
    adminMetaCourse: adminMetaCourseSliceReducer,
    adminAgencyCourse: adminAgencyCourseSliceReducer,
    visitorLead: visitorLeadSliceReducer,
    ieltsLead: ieltsLeadSliceReducer,
    insuranceLead: insuranceLeadSliceReducer,
    insuranceCommission: insuranceCommissionSliceReducer,
    adminInsurancePolicy: adminInsurancePolicySliceReducer,
    adminInsuranceOrder: adminInsuranceOrderSliceReducer,
    adminInsuranceCompanyInvoice: adminInsuranceCompanyInvoiceSliceReducer,
    adminInsuranceAgencyInvoice: adminInsuranceAgencyInvoiceSliceReducer,
    coachingLead: coachingLeadSliceReducer,
    entityForm: entityFormSliceReducer,
    universityPayment: universityPaymentSliceReducer,
    announcement: announcementSliceReducer,
    workflow: workflowSliceReducer,
    b2bDashboard: b2bDashboardSliceReducer,
    hubDashboard: hubDashboardSliceReducer,
    b2bCommissionInvoice: b2bCommissionInvoiceReducer,
    b2bCommissionLead: b2bCommissionLeadReducer,
    hubCommissionInvoice: hubCommissionInvoiceReducer,
    b2bContract: b2bContractSliceReducer,
    migrateLead: migrateLeadSliceReducer,
    admissionLoan: loanSliceReducer,
    hubSubAgent: hubSubAgentSliceReducer,
    hubVisitorLead: hubVisitorLeadSliceReducer,
    b2bVisitorLead: b2bVisitorLeadReducer,
    courseFinder: courseFinderSliceReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
