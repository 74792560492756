import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Agent = App.Models.Agent

export interface B2BAgentState {
  b2b_agent_id?: number | null
  show_b2b_add_agent_modal: boolean
  b2b_agent_form_submitted: boolean
  show_b2b_agent_modal: boolean
  show_b2b_agent_details_drawer: boolean
  b2b_selected_agent: Agent | null
  show_b2b_relationship_manager_change_modal: boolean
  branch_id: number | null
  b2b_selected_relationship_manager_id: number | null
  b2b_selected_relationship_manager_id_one: number | null
  b2b_selected_relationship_manager_id_two: number | null
  b2b_selected_relationship_manager_id_three: number | null
  b2b_selected_relationship_manager_id_four: number | null
  b2b_selected_relationship_manager_id_five: number | null
  b2b_selected_relationship_manager_id_six: number | null
  b2b_selected_relationship_manager_id_seven: number | null
  show_b2b_agent_password_reset_modal: boolean
  show_b2b_agent_copy_login_credentials_modal: boolean
  show_b2b_agent_graph_drawer: boolean
}

const initialState: B2BAgentState = {
  b2b_agent_id: undefined,
  show_b2b_add_agent_modal: false,
  b2b_agent_form_submitted: false,
  show_b2b_agent_modal: false,
  b2b_selected_agent: null,
  show_b2b_agent_details_drawer: false,
  show_b2b_relationship_manager_change_modal: false,
  b2b_selected_relationship_manager_id: null,
  b2b_selected_relationship_manager_id_one: null,
  b2b_selected_relationship_manager_id_two: null,
  b2b_selected_relationship_manager_id_three: null,
  b2b_selected_relationship_manager_id_four: null,
  b2b_selected_relationship_manager_id_five: null,
  b2b_selected_relationship_manager_id_six: null,
  b2b_selected_relationship_manager_id_seven: null,
  branch_id: null,
  show_b2b_agent_password_reset_modal: false,
  show_b2b_agent_copy_login_credentials_modal: false,
  show_b2b_agent_graph_drawer: false,
}

export const b2bAgentSlice = createSlice({
  name: 'b2bAgentSlice',
  initialState,
  reducers: {
    updateB2BAgentId(state, action: PayloadAction<number | undefined>) {
      state.b2b_agent_id = action.payload
    },
    updateShowB2BAddAgentModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_b2b_add_agent_modal = true
      } else {
        state.b2b_agent_id = undefined
        state.show_b2b_add_agent_modal = false
        state.b2b_agent_form_submitted = false
      }
    },
    updateB2BAgentFormSubmitted(state, action: PayloadAction<boolean>) {
      state.b2b_agent_form_submitted = action.payload
    },
    updateShowB2BAgentModal(state, action: PayloadAction<boolean>) {
      state.show_b2b_agent_modal = action.payload
    },
    updateShowB2BAgentDetailsDrawer(state, action: PayloadAction<boolean>) {
      state.show_b2b_agent_details_drawer = action.payload
    },
    updateB2BSelectedAgent(state, action: PayloadAction<Agent | null>) {
      state.b2b_selected_agent = action.payload
    },
    updateShowB2BRelationshipManagerChangeModal(
      state,
      action: PayloadAction<{
        show: boolean
        agent: Agent | null
        branch_id: number | null
        relationship_manager_id: number | null
        relationship_manager_id_one: number | null
        relationship_manager_id_two: number | null
        relationship_manager_id_three: number | null
        relationship_manager_id_four: number | null
        relationship_manager_id_five: number | null
        relationship_manager_id_six: number | null
        relationship_manager_id_seven: number | null
      }>
    ) {
      state.show_b2b_relationship_manager_change_modal = action.payload.show
      state.b2b_selected_agent = action.payload.agent
      state.branch_id = action.payload.branch_id
      state.b2b_selected_relationship_manager_id = action.payload.relationship_manager_id
      state.b2b_selected_relationship_manager_id_one = action.payload.relationship_manager_id_one
      state.b2b_selected_relationship_manager_id_two = action.payload.relationship_manager_id_two
      state.b2b_selected_relationship_manager_id_three = action.payload.relationship_manager_id_three
      state.b2b_selected_relationship_manager_id_four = action.payload.relationship_manager_id_four
      state.b2b_selected_relationship_manager_id_five = action.payload.relationship_manager_id_five
      state.b2b_selected_relationship_manager_id_six = action.payload.relationship_manager_id_six
      state.b2b_selected_relationship_manager_id_seven = action.payload.relationship_manager_id_seven
    },
    updateShowB2BAgentPasswordResetModal(
      state,
      action: PayloadAction<{
        show: boolean
        agent: Agent | null
        agent_id: number | null
      }>
    ) {
      state.show_b2b_agent_password_reset_modal = action.payload.show
      state.b2b_selected_agent = action.payload.agent
      state.b2b_agent_id = action.payload.agent_id
    },
    updateShowB2BAgentCopyLoginCredentialsModal(state, action: PayloadAction<boolean>) {
      state.show_b2b_agent_copy_login_credentials_modal = action.payload
    },
    showAgentGraphDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        agent: Agent | null
      }>
    ) {
      state.show_b2b_agent_graph_drawer = action.payload.show
      state.b2b_selected_agent = action.payload.agent
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateB2BAgentId,
  updateShowB2BAddAgentModal,
  updateB2BAgentFormSubmitted,
  updateShowB2BAgentModal,
  updateB2BSelectedAgent,
  updateShowB2BRelationshipManagerChangeModal,
  updateShowB2BAgentPasswordResetModal,
  updateShowB2BAgentCopyLoginCredentialsModal,
  updateShowB2BAgentDetailsDrawer,
  showAgentGraphDrawer,
} = b2bAgentSlice.actions

export default b2bAgentSlice.reducer
