import React, { useEffect } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconX } from '@tabler/icons-react'
import XSelect from '@/Mantine/XSelect'
import { XTextarea } from '@/Mantine/XTextarea'
import { useForm } from '@inertiajs/react'
import useAuth from '@/Hooks/useAuth'
import XNumberInput from '@/Mantine/XNumberInput'

interface CallLogModalProps {
  entity: string
  entity_id: string
  duration?: number
  status?: string
  log?: string
  outcome?: string
}

const LogCallModal = () => {
  const auth = useAuth()
  const [opened, { open, close }] = useDisclosure(false)
  const { data, setData, post, errors } = useForm<CallLogModalProps>({
    entity: '',
    entity_id: '',
    duration: 0,
    status: 'Accepted',
    log: '',
    outcome: '',
  })

  const formHandler = (name: string, value: string | number) => {
    setData((prev) => {
      const datum: { [key: string]: string | number } = {}
      datum[name] = value

      return {
        ...prev,
        ...datum,
      }
    })
  }

  useEffect(() => {
    window.Echo.private(`App.Models.User.${auth.user.id}`).listen('.call.log', (data: CallLogModalProps) => {
      if (document.visibilityState === 'hidden') {
        return
      }

      setData({
        entity: data.entity,
        entity_id: data.entity_id,
      })
      open()
    })

    return () => {
      window.Echo.private(`App.Models.User.${auth.user.id}`).stopListening('.call.log')
    }
  }, [])

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={true}
      closeOnClickOutside={true}
      withCloseButton={false}
      trapFocus={false}
      radius={16}
      size={480}
      opened={opened}
      onClose={close}
    >
      <VStack
        spacing="12px"
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        style={{ position: 'relative' }}
      >
        <HStack justifyContent={'space-between'}>
          <Typography variant={'subheadline'} color={XelaColor.Gray3}>
            Call Log Details
          </Typography>
          <IconX color={XelaColor.Gray3} size={20} onClick={close} />
        </HStack>
        <Divider variant={'dotted'} />
        <VStack className={''} height={'240px'} spacing={'12px'}>
          <HStack spacing={'12px'} alignItems={'flex-start'}>
            <XSelect
              label={'Call Status'}
              data={['Accepted', 'Rejected', 'Missed', 'Not Answered']}
              value={data.status}
              onChange={(value) => {
                if (value) {
                  formHandler('status', value)
                }
              }}
              error={errors.status}
            />
            <XSelect
              label={'Call Outcome'}
              data={['Call Back', 'Not Picked', 'Not Interested']}
              value={data.outcome}
              onChange={(value) => {
                if (value) {
                  formHandler('outcome', value)
                }
              }}
              error={errors.outcome}
            />
          </HStack>
          {data.status === 'Accepted' && (
            <HStack spacing={'12px'} alignItems={'flex-start'}>
              <XNumberInput
                label={'Duration'}
                value={data.duration}
                onChange={(value) => {
                  if (value) {
                    formHandler('duration', value)
                  }
                }}
                error={errors.duration}
                defaultValue={0}
              />
            </HStack>
          )}
          <XTextarea
            size={'sm'}
            placeholder={'Call Notes'}
            error={errors.log}
            value={data.log}
            onChange={(event) => {
              if (event) {
                formHandler('log', event.target.value)
              }
            }}
          />
        </VStack>
        <VStack spacing={'12px'}>
          <Divider variant={'dotted'} />
          <HStack spacing={'12px'} justifyContent={'flex-end'}>
            <Button label={'Cancel'} variant={'secondary'} onClick={close} />
            <Button
              label={'Submit'}
              onClick={() => {
                post(route('call-logs.create'), {
                  preserveScroll: true,
                  preserveState: true,
                  onSuccess: () => {
                    close()
                  },
                })
              }}
            />
          </HStack>
        </VStack>
      </VStack>
    </Modal>
  )
}

export default LogCallModal
