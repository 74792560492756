import React, { FC, useEffect, useState } from 'react'
import { HStack } from '@/XelaReact'
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react'
import { router, usePage } from '@inertiajs/react'
import { showNotification } from '@mantine/notifications'
import Notifications from '@/Components/Notifications/Notifications'
import B2BSidebar from '@/Components/B2B/B2BSidebar'
import DocumentViewerModal from '@/Layouts/Modals/DocumentViewerModal'
import GlobalSearchModal from '@/Layouts/Modals/GlobalSearchModal'
import B2BAddQuickLeadModal from '@/Layouts/B2B/Modals/B2BAddQuickLeadModal'
import AddB2BAgentModal from '@/Layouts/B2B/Modals/AddB2BAgentModal'
import EditB2BAgentModal from '@/Layouts/B2B/Modals/EditB2BAgentModal'
import CreateFollowUpModal from '@/Layouts/Modals/CreateFollowUpModal'
import DownloadDocumentsModal from '@/Layouts/Modals/DownloadDocumentsModal'
import UploadDocumentsModal from '@/Layouts/Modals/UploadDocumentsModal'
import B2BAgentPasswordResetModal from '@/Layouts/B2B/Modals/B2BAgentPasswordResetModal'
import CopyLoginCredentialsAgentModal from '@/Layouts/B2B/Modals/CopyLoginCredentialsAgentModal'
import { LoadingOverlay } from '@mantine/core'
import CreateTaskModal from '@/Layouts/Modals/CreateTaskModal'
import EditTaskModal from '@/Layouts/Modals/EditTaskModal'
import EditUploadDocumentsModal from '@/Layouts/Modals/EditUploadDocumentsModal'
import B2BAgentDetailsDrawer from '@/Layouts/B2B/Drawers/B2BAgentDetailsDrawer'
import B2BAddQuickVisitorLeadModal from '@/Layouts/B2B/Modals/B2BAddQuickVisitorLeadModal'

const B2BAuthenticated: FC<{ children: React.ReactNode }> = ({ children }) => {
  const flash = usePage().props.flash as Record<string, unknown>
  const [loadingIndicator, setLoadingIndicator] = useState<boolean>(false)

  useEffect(() => {
    if (flash.message) {
      showNotification({
        withCloseButton: true,
        autoClose: 5000,
        title: 'Success',
        message: flash.message as string,
        color: 'green',
        icon: <IconCheck />,
      })
    }

    if (flash.error) {
      showNotification({
        withCloseButton: true,
        autoClose: 5000,
        title: 'Error',
        message: flash.error as string,
        color: 'red',
        icon: <IconAlertTriangle />,
      })
    }
  }, [flash.timestamp, flash.message, flash.error])

  useEffect(() => {
    return router.on('start', (event) => {
      if (event.detail.visit.method === 'get') {
        setLoadingIndicator(true)
      }
    })
  }, [])

  useEffect(() => {
    return router.on('finish', () => {
      setLoadingIndicator(false)
    })
  }, [])

  return (
    <>
      <LoadingOverlay visible={loadingIndicator} color={'blueX'} />
      <div className="w-full" style={{ position: 'relative', height: '100vh' }}>
        <div style={{ position: 'absolute', right: 24, top: 14 }}>
          <Notifications portal={'B2B'} />
        </div>
        <HStack alignItems="flex-start" style={{ alignItems: 'stretch' }}>
          <B2BSidebar />
          {children}
        </HStack>
      </div>
      <GlobalSearchModal portal={'B2B'} />
      <AddB2BAgentModal></AddB2BAgentModal>
      <CopyLoginCredentialsAgentModal></CopyLoginCredentialsAgentModal>
      <EditB2BAgentModal />
      <B2BAgentDetailsDrawer />
      <DocumentViewerModal />
      <B2BAddQuickLeadModal />
      <CreateFollowUpModal />
      <UploadDocumentsModal portal={'B2B'} />
      <DownloadDocumentsModal />
      <B2BAgentPasswordResetModal />
      <CreateTaskModal />
      <EditTaskModal />
      <EditUploadDocumentsModal />
      <B2BAddQuickVisitorLeadModal />
    </>
  )
}

export default B2BAuthenticated
