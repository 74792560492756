import useAuth from '@/Hooks/useAuth'
import useWidthResize from '@/Hooks/useWidthResize'
import { Button, ChipX, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { getInitials, getRouteFromEntity } from '@/helpers'
import { router } from '@inertiajs/react'
import { Avatar, Box, Modal, Spoiler, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconChevronLeft, IconChevronRight, IconCircleCheck } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'

interface TaskAndFollowUpPopUpModalProps {
  type: string
  item: {
    entity: string
    entity_id: number
    agent_id: number
    lead?: {
      id: string
      name: string
      phone: string
    }
    assignees: App.Models.User[]
    follow_up: string
    description: string
    created_by?: App.Models.User
    due_date: string
    id: string
    is_completed: boolean
    due_for_humans: string
    updated_at_for_humans: string
  }
}

const TaskAndFollowUpPopUpModal = () => {
  const auth = useAuth()
  const width = useWidthResize()

  const [opened, { open, close }] = useDisclosure(false)
  const [data, setData] = React.useState<TaskAndFollowUpPopUpModalProps[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(data.length)

  useEffect(() => {
    window.Echo.private(`App.Models.User.${auth.user.id}`).listen(
      '.task.followup',
      (data: { data: TaskAndFollowUpPopUpModalProps[] }) => {
        if (document.visibilityState === 'hidden') {
          return
        }

        setData(data.data)
        open()
      }
    )

    return () => {
      window.Echo.private(`App.Models.User.${auth.user.id}`).stopListening('.task.followup')
    }
  }, [])

  const handleNext = () => {
    if (data && currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  useEffect(() => {
    if (data) {
      setTotalCount(data.length - currentIndex)
    }
  }, [currentIndex, data])

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={true}
      closeOnClickOutside={true}
      withCloseButton={false}
      trapFocus={false}
      radius={16}
      size={600}
      opened={opened}
      onClose={close}
      padding={0}
      transitionProps={{ duration: 200 }}
    >
      <VStack style={{ position: 'relative' }}>
        {data && data[currentIndex] && (
          <VStack spacing="16px" style={{ padding: '16px', position: 'relative' }}>
            <HStack justifyContent={'space-between'}>
              <HStack alignItems={'flex-start'}>
                <Typography variant={'body-bold'} style={{ fontWeight: 800 }} onClick={close}>
                  {data[currentIndex].type === 'task' ? 'Task' : 'Follow Up'} Due in 15 minutes
                </Typography>
              </HStack>
              {data.length > 1 && (
                <HStack justifyContent={'flex-end'}>
                  <IconChevronLeft
                    width={16}
                    height={16}
                    color={XelaColor.Blue3}
                    onClick={handlePrev}
                    style={{
                      cursor: currentIndex === 0 ? 'not-allowed' : 'pointer',
                    }}
                  />
                  <Typography color={XelaColor.Blue3} variant={'body-small'} style={{ fontWeight: 700 }}>
                    {totalCount} items
                  </Typography>
                  <IconChevronRight
                    width={16}
                    height={16}
                    color={XelaColor.Blue3}
                    onClick={handleNext}
                    style={{
                      cursor: currentIndex === data.length - 1 ? 'not-allowed' : 'pointer',
                    }}
                  />
                </HStack>
              )}
            </HStack>
            <HStack>
              <Divider variant="dotted"></Divider>
            </HStack>
            {data[currentIndex].item.lead && (
              <HStack justifyContent={'space-between'}>
                <VStack>
                  <Typography variant={'body-small-bold'}>{data[currentIndex].item.lead?.name}</Typography>
                  <Typography variant={'body-small'} style={{ fontWeight: 600 }}>
                    {data[currentIndex].item.lead?.phone}
                  </Typography>
                </VStack>
                <HStack justifyContent={'flex-end'}>
                  <ChipX
                    label={data[currentIndex].item.due_for_humans}
                    deactiveBg={XelaColor.Red12}
                    deactiveLabelColor={XelaColor.Red2}
                  ></ChipX>
                </HStack>
              </HStack>
            )}

            <HStack>
              {data[currentIndex].type === 'task' &&
                data[currentIndex].item &&
                data[currentIndex].item.description &&
                data[currentIndex].item.description.length > 0 &&
                (data[currentIndex].item.description.replace(' ', '') !== '<p></p>' ||
                  data[currentIndex].item.description.replace(' ', '') !== '<p><br/></p>') && (
                  <HStack>
                    <Spoiler
                      style={{
                        lineHeight: '12px',
                      }}
                      maxHeight={32}
                      showLabel={
                        <Typography variant="sub-caption" color={XelaColor.Blue3}>
                          Show More
                        </Typography>
                      }
                      hideLabel={
                        <Typography variant="sub-caption" color={XelaColor.Blue3}>
                          Show Less
                        </Typography>
                      }
                    >
                      <p
                        style={{
                          color: XelaColor.Gray4,
                          margin: 0,
                          fontSize: '0.75rem',
                          lineHeight: '1rem',
                          fontWeight: 600,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: data[currentIndex].item.description ? data[currentIndex].item.description : '',
                        }}
                      ></p>
                    </Spoiler>
                  </HStack>
                )}
              {data[currentIndex].type === 'follow_up' && (
                <HStack width="90%">
                  <Spoiler
                    style={{
                      lineHeight: '12px',
                    }}
                    maxHeight={32}
                    showLabel={
                      <Typography variant="sub-caption" color={XelaColor.Blue3}>
                        Show More
                      </Typography>
                    }
                    hideLabel={
                      <Typography variant="sub-caption" color={XelaColor.Blue3}>
                        Show Less
                      </Typography>
                    }
                  >
                    <Typography variant="caption" color={XelaColor.Gray4}>
                      {data[currentIndex].item.follow_up}
                    </Typography>
                  </Spoiler>
                </HStack>
              )}
            </HStack>
            <HStack>
              <HStack spacing="2px">
                <Typography variant={'body-small'} style={{ fontWeight: 600 }} color={XelaColor.Gray6}>
                  Created by
                </Typography>
                <Typography variant={'body-small'} style={{ fontWeight: 600 }} color={XelaColor.Blue3}>
                  {data[currentIndex].item.created_by?.name}
                </Typography>
              </HStack>
              {data[currentIndex].item.assignees && (
                <HStack spacing="4px" justifyContent={'flex-end'}>
                  <Tooltip.Group openDelay={200} closeDelay={100}>
                    <Avatar.Group spacing={'sm'}>
                      {data[currentIndex].item.assignees.slice(0, 3).map((assignee, index) => {
                        return (
                          <Tooltip
                            key={assignee.id}
                            position="top-start"
                            label={auth.user.id === assignee.id ? assignee.name + ' (You)' : assignee.name}
                            withArrow
                          >
                            <Avatar
                              className="mr-1.5"
                              variant="filled"
                              radius="xl"
                              color={`${index % 3 === 0 ? 'orange.3' : index % 3 === 1 ? 'pink.3' : 'green.2'}`}
                              size={width > 1280 ? 30 : 24}
                            >
                              <Typography
                                style={{ fontSize: width > 1280 ? '11px' : '9px' }}
                                color={
                                  index % 3 === 0
                                    ? XelaColor.Orange1
                                    : index % 3 === 1
                                    ? XelaColor.Red1
                                    : XelaColor.Green1
                                }
                              >
                                {getInitials(assignee.first_name, assignee.last_name)}
                              </Typography>
                            </Avatar>
                          </Tooltip>
                        )
                      })}
                      {data[currentIndex].item.assignees.length > 3 ? (
                        <>
                          <Tooltip
                            position="right"
                            withArrow
                            label={data[currentIndex].item.assignees.map((item, index) => (
                              <Box key={index}>{item.name}</Box>
                            ))}
                          >
                            <Box className={'flex items-end pb-1'}>
                              <Typography
                                as={'span'}
                                variant={'sub-caption-bold'}
                                className={'h-fit cursor-pointer'}
                                color={XelaColor.Blue3}
                              >
                                + Show more
                              </Typography>
                            </Box>
                          </Tooltip>
                        </>
                      ) : null}
                    </Avatar.Group>
                  </Tooltip.Group>
                </HStack>
              )}
            </HStack>
            <HStack>
              <Divider variant="dotted"></Divider>
            </HStack>
            <HStack justifyContent={'space-between'}>
              <HStack spacing={'12px'} justifyContent={'flex-start'}>
                <Button
                  label={'Open'}
                  size={'small'}
                  variant={'secondary'}
                  color={XelaColor.Blue3}
                  onClick={() => {
                    close()
                    if (data[currentIndex].type === 'task') {
                      if (data[currentIndex].item.entity) {
                        router.visit(
                          getRouteFromEntity(
                            data[currentIndex].item.entity,
                            data[currentIndex].item.entity_id,
                            data[currentIndex].item.agent_id,
                            'tasks'
                          )
                        )
                      } else {
                        router.visit(`/tasks?task_id=${data[currentIndex].item.id}`)
                      }
                    } else {
                      router.visit(`/leads/${data[currentIndex].item.lead?.id}?show_tab=followUps`)
                    }
                  }}
                />
                <Button
                  label={'Mark Complete'}
                  style={{ padding: '16px' }}
                  leftIcon={<IconCircleCheck width={15} height={15}></IconCircleCheck>}
                  variant={'primary'}
                  size={'small'}
                  color={XelaColor.Blue3}
                  onClick={() => {
                    if (data[currentIndex].type === 'task') {
                      axios
                        .post(route('tasks.mark-complete', { id: data[currentIndex].item.id }), {
                          is_completed: true,
                        })
                        .then(() => {
                          close()
                        })
                    } else {
                      axios
                        .post(route('follow-ups.mark-complete', { id: data[currentIndex].item.id }), {
                          is_completed: true,
                        })
                        .then(() => {
                          close()
                        })
                    }
                  }}
                />
              </HStack>
              <HStack spacing={'12px'} justifyContent={'flex-end'}>
                {data.length > 1 && (
                  <Button
                    label={'Next'}
                    size={'small'}
                    variant={'secondary'}
                    color={XelaColor.Blue3}
                    onClick={() => {
                      handleNext()
                    }}
                  />
                )}
                <Button
                  label={'Close'}
                  size={'small'}
                  variant={'secondary'}
                  color={XelaColor.Blue3}
                  onClick={() => {
                    close()
                  }}
                />
              </HStack>
            </HStack>
          </VStack>
        )}
      </VStack>
    </Modal>
  )
}

export default TaskAndFollowUpPopUpModal
