import React, { FC, useEffect, useState } from 'react'
import { HStack } from '@/XelaReact'
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react'
import { router, usePage } from '@inertiajs/react'
import { showNotification } from '@mantine/notifications'
import Notifications from '@/Components/Notifications/Notifications'
import StudentSidebar from '@/Components/Student/StudentSidebar'
import DocumentViewerModal from '@/Layouts/Modals/DocumentViewerModal'
import GlobalSearchModal from '@/Layouts/Modals/GlobalSearchModal'
import { LoadingOverlay } from '@mantine/core'
import useWidthResize from '@/Hooks/useWidthResize'
import UploadDocumentsModal from '@/Layouts/Modals/UploadDocumentsModal'

const StudentAuthenticated: FC<{ children: React.ReactNode }> = ({ children }) => {
  const flash = usePage().props.flash as Record<string, unknown>
  const [loadingIndicator, setLoadingIndicator] = useState<boolean>(false)
  const dimension = useWidthResize()

  useEffect(() => {
    if (flash.message) {
      showNotification({
        withCloseButton: true,
        autoClose: 5000,
        title: 'Success',
        message: flash.message as string,
        color: 'green',
        icon: <IconCheck />,
      })
    }

    if (flash.error) {
      showNotification({
        withCloseButton: true,
        autoClose: 5000,
        title: 'Error',
        message: flash.error as string,
        color: 'red',
        icon: <IconAlertTriangle />,
      })
    }
  }, [flash.timestamp, flash.message, flash.error])

  useEffect(() => {
    return router.on('start', (event) => {
      if (event.detail.visit.method === 'get') {
        setLoadingIndicator(true)
      }
    })
  }, [])

  useEffect(() => {
    return router.on('finish', () => {
      setLoadingIndicator(false)
    })
  }, [])

  return (
    <>
      <LoadingOverlay visible={loadingIndicator} color={'blueX'} />
      <div className="w-full" style={{ position: 'relative', height: '100vh' }}>
        <div style={{ position: dimension < 600 ? 'fixed' : 'absolute', right: 24, top: 14, zIndex: 100 }}>
          <Notifications portal={'Student'} />
        </div>
        <HStack alignItems="flex-start" style={{ alignItems: 'stretch', overflowX: 'hidden' }}>
          <StudentSidebar />
          {/*<div*/}
          {/*  style={{*/}
          {/*    width: '100%',*/}
          {/*    overflowX: 'auto',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {dimension < 600 && <MobileHeader />}*/}
          {/*  <HStack>{children}</HStack>*/}
          {/*</div>*/}
          {children}
        </HStack>
        <GlobalSearchModal portal={'Student'} />
        <UploadDocumentsModal portal={'Student'} />
        <DocumentViewerModal />
      </div>
    </>
  )
}

export default StudentAuthenticated
