import React, { useEffect } from 'react'
import { Button, Divider, HStack, Typography, VStack } from '@/XelaReact'
import { Modal } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { useForm } from '@inertiajs/react'
import XSelect from '@/Mantine/XSelect'
import { updateShowDocumentEditor } from '@/Store/globalSlice'
import { debounce } from 'lodash'
import axios from 'axios'
import FileUpload from '@/XelaReact/FileUpload/FileUpload'
import collect from 'collect.js'
import DOCUMENT_TYPES from '@/Constants/DocumentTypeMeta'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import Document = App.Models.Document

const DocumentEditorModal = () => {
  const dispatch = useDispatch()
  const globalStore = useSelector((state: RootState) => state.global)

  const [document, setDocument] = React.useState<Document | null>(null)

  const [leadNames, setLeadNames] = React.useState<{ value: string; label: string }[]>([])

  const { data, setData, post, errors, reset, recentlySuccessful } = useForm<{
    lead_id: string
    document_type: string
    document_group: string
    document: File | null
  }>({
    lead_id: '',
    document_type: '',
    document_group: '',
    document: null,
  })

  useEffect(() => {
    if (recentlySuccessful) {
      dispatch(updateShowDocumentEditor({ show: false }))
      reset()
    }
  }, [recentlySuccessful])

  useEffect(() => {
    if (globalStore.documentType) {
      setData((prevData) => ({
        ...prevData,
        lead_id: globalStore.documentType || '',
        document_type: globalStore.documentType || '',
        document_group: globalStore.documentType
          ? collect(DOCUMENT_TYPES).where('value', globalStore.documentType)?.first().group
          : '',
      }))
    }

    if (globalStore.documentLeadId && globalStore.documentType) {
      axios
        .get<{ success: boolean; data: Document | null }>('/documents/find', {
          params: {
            lead_id: globalStore.documentLeadId,
            type: globalStore.documentType,
          },
        })
        .then((response) => {
          if (response.data.success) setDocument(response.data.data)
        })
    }
  }, [globalStore.documentType, globalStore.documentLeadId])

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      radius={16}
      size={800}
      opened={globalStore.showDocumentEditor}
      onClose={() => {
        dispatch(updateShowDocumentEditor({ show: false }))
        reset()
      }}
    >
      <VStack spacing="12px">
        <HStack justifyContent="flex-end">
          <HStack>
            <Typography variant={'subheadline'}>Document Viewer</Typography>
          </HStack>
          {!document && (
            <HStack width="500px">
              <XSelect
                searchable
                clearable
                error={errors.lead_id}
                label={'Lead'}
                value={data.lead_id}
                data={leadNames}
                onChange={(value) => {
                  if (value)
                    setData((prevData) => ({
                      ...prevData,
                      lead_id: value,
                    }))
                }}
                onKeyDown={debounce((event) => {
                  if (event.target) {
                    axios
                      .post<{ id: string; display: string }[]>(route('select.leads.lead-names'), {
                        query: event.target.value,
                      })
                      .then((response) => {
                        if (response.data.length)
                          setLeadNames(
                            response.data.map((lead) => {
                              return { value: lead.id, label: lead.display }
                            })
                          )
                      })
                  }
                }, 250)}
              />
            </HStack>
          )}
        </HStack>
        <>
          {document !== null && document.mime_type === 'application/pdf' && (
            <HStack
              style={{
                padding: '12px',
                height: '100%',
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={document.url} />
              </Worker>
            </HStack>
          )}
          {document !== null && document.mime_type !== 'application/pdf' && (
            <HStack
              style={{
                padding: '12px',
                height: '100%',
              }}
            >
              <DocViewer
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                  },
                }}
                pluginRenderers={DocViewerRenderers}
                documents={[
                  {
                    uri: document.url,
                    fileName: document.name,
                  },
                ]}
              />
            </HStack>
          )}
        </>
        {!document && (
          <HStack style={{ padding: '0 5px' }}>
            <FileUpload
              height="200px"
              error={errors['document'] !== undefined}
              helperText={errors['document']}
              value={data.document}
              typeUploadText="PNG,JPG,JPEG,Word,Excel (max. 200 MB)"
              options={{
                onDrop: (acceptedFiles: File[]) => {
                  if (acceptedFiles.length > 0) {
                    setData((prevData) => ({
                      ...prevData,
                      document: acceptedFiles[0],
                    }))
                  }
                },
              }}
            ></FileUpload>
          </HStack>
        )}
        <HStack>
          <Divider variant={'dotted'}></Divider>
        </HStack>
        <HStack spacing={'12px'} justifyContent="flex-end">
          <Button
            label={'Cancel'}
            variant={'secondary'}
            onClick={() => {
              dispatch(updateShowDocumentEditor({ show: false }))
            }}
          ></Button>
          <Button
            label={'Import'}
            onClick={() => {
              post('/documents/upload-document-by-viewer', {
                data: data,
                preserveState: true,
                preserveScroll: true,
              })
            }}
          ></Button>
        </HStack>
      </VStack>
    </Modal>
  )
}

export default DocumentEditorModal
